import Head from 'next/head';
import React from 'react';
import { BiLockAlt } from 'react-icons/bi';
import { FaThinkPeaks, FaChalkboardTeacher } from 'react-icons/fa';
import { MdOutlineInsertDriveFile, MdSignalCellularAlt } from 'react-icons/md';
import { TbActivityHeartbeat, TbBrowser, TbFileExport } from 'react-icons/tb';

import CustomerTestimonial from '../components/marketing/CustomerTestimonial';
import FeatureList from '../components/marketing/FeatureList';
import { Link } from '../components/marketing/Link';
import { LinksChildren } from '../components/marketing/LinksChildren';

export default function Home() {
  return (
    <>
      <Head>
        <title>
          NMRium - Visualize, analyze and process NMR spectra online
        </title>
        <meta
          name="description"
          content="A software to visualize and analyze 1D and 2D NMR spectra for free. Designed for analytical and organic chemists as well as for teaching."
        />
      </Head>

      <CustomerTestimonial
        linksChildren={<LinksChildren />}
        name={
          <CustomerTestimonial.Description>
            <div className="">
              <p>NMR spectra processing for everybody</p>
            </div>
          </CustomerTestimonial.Description>
        }
      >
        <CustomerTestimonial.Description>
          Unrestrained access to first-class online software for NMR spectra
          processing
          <br /> It is free and you can get started right away from your
          browser.
        </CustomerTestimonial.Description>
      </CustomerTestimonial>

      <FeatureList>
        <FeatureList.Item
          title="Process directly online"
          icon={<TbBrowser size="30px" />}
        >
          You don&apos;t have to go through the hassle of installing any
          software or applications. Click <Link href="/nmrium">here</Link> to
          start.
        </FeatureList.Item>
        <FeatureList.Item
          title="1D and 2D spectra"
          icon={<TbActivityHeartbeat size="30px" />}
        >
          NMRium accepts 1D and 2D spectras. <br />
          For 1D spectra it can either be a FID or a fourrier transformed
          spectrum. <br /> Currently, only FT 2D spectra are allowed.
        </FeatureList.Item>
        <FeatureList.Item
          title="Smart peak picking"
          icon={<FaThinkPeaks size="26px" />}
        >
          NMRium includes an advanced peak picking detection for 1D and 2D
          spectras and is able to generate the NMR string required for
          publication or patent.
        </FeatureList.Item>
        <FeatureList.Item title="Export" icon={<TbFileExport size="25px" />}>
          All the processing and assignment can be stored as a
          &ldquo;.nmrium&rdquo; file. <br />
          This file contains the original data as well as all the processing
          that was applied on the spectrum. <br />
          Assignment of the molecule are also saved in the file.
        </FeatureList.Item>
        <FeatureList.Item
          title="Not just signal processing"
          icon={<MdSignalCellularAlt size="26px" />}
        >
          NMRium also handles chemical structures. <br />
          They can either be imported from a MDL Molfile, copy pasted directly
          in the molecule panel or drawn.
        </FeatureList.Item>
        <FeatureList.Item
          title="Perfect for teaching"
          icon={<FaChalkboardTeacher size="26px" />}
        >
          <Link href="/teaching">Try out</Link> our structure elucidation
          exercises or create your own exercises ! <br />
          They are great for students.
        </FeatureList.Item>
        <FeatureList.Item
          title="Great user experience"
          icon={<BiLockAlt size="25px" />}
        >
          To provide an optimal user experience, the spectra processing is
          efficiently performed within the web browser.
        </FeatureList.Item>
        <FeatureList.Item
          title="Opens multiple file formats"
          icon={<MdOutlineInsertDriveFile size="25px" />}
          isSmall
        >
          Just drag and drop a JCAMP-DX file, a bruker folder or a JEOL file.
        </FeatureList.Item>
      </FeatureList>
    </>
  );
}
